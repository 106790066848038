<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <v-card class="pa-3 radius-card">
        <h3>Management Balance</h3>
        <div class="d-flex justify-space-between" v-if="profile">
          <v-card class="text-center rounded-lg" outlined width="max-content">
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 0 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 0;
                fetchItems();
              "
            >
              <div class="unsend"></div>
              <p class="small_txt color_txt mb-0 ml-2">Pending</p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 1 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 1;
                fetchItems();
              "
            >
              <div class="sent"></div>
              <p class="small_txt color_txt mb-0 ml-2">
                Terkonfirmasi, Menunggu di transfer
              </p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 2 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 2;
                fetchItems();
              "
            >
              <div class="repair"></div>
              <p class="small_txt color_txt mb-0 ml-2">
                Ditransfer, Menunggu konfirmasi psikolog
              </p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 3 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 3;
                fetchItems();
              "
            >
              <div class="confirm"></div>
              <p class="small_txt color_txt mb-0 ml-2">Terkonfirmasi</p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 4 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 4;
                fetchItems();
              "
            >
              <div class="nofill"></div>
              <p class="small_txt color_txt mb-0 ml-2">Rejected</p>
            </div>
            <div
              v-if="setStatus !== ''"
              class="d-flex align-center pa-2 cursor"
              @click="
                setStatus = '';
                fetchItems();
              "
            >
              <div class="close"></div>
              <p class="small_txt color_txt mb-0 ml-2">Reset</p>
            </div>
          </v-card>
          <tarik-dana-psy
            :dialogDana="dialogDana"
            :dana="profile.my_balance"
            @close="dialogDana = false"
            @refetch="fetchItems"
          />
          <v-card
            width="250px"
            height="max-content"
            class="pa-3 radius-card d-flex justify-space-between"
            v-if="profile"
          >
            <div>
              <div class="d-flex align-center">
                <v-icon small class="mr-2"
                  >mdi-credit-card-check-outline</v-icon
                >
                <p class="ma-0 small_txt color_txt">Balance</p>
              </div>
              <b
                >Rp.
                {{
                  profile.my_balance
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </b>
            </div>
            <v-btn
              class="text-capitalize"
              dark
              color="orange"
              @click="dialogDana = true"
              depressed
              >Cairkan</v-btn
            >
          </v-card>
        </div>
        <div v-if="list">
          <v-data-table
            :headers="headers"
            :items="list.data"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.status_withdraw`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      item.status_withdraw == 0
                        ? 'unsend'
                        : item.status_withdraw == 1
                        ? 'sent'
                        : item.status_withdraw == 2
                        ? 'repair'
                        : item.status_withdraw == 3
                        ? 'confirm'
                        : 'nofill'
                    "
                  ></div>
                </template>
                <span v-if="item.status_withdraw == 0">Pending</span>
                <span v-if="item.status_withdraw == 1"
                  >Terkonfirmasi, Menunggu di transfer</span
                >
                <span v-if="item.status_withdraw == 2"
                  >Ditransfer, Menunggu konfirmasi psikolog</span
                >
                <span v-if="item.status_withdraw == 3">Terkonfirmasi</span>
                <span v-if="item.status_withdraw == 4">Rejected</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.request`]="{ item }">
              <p class="ma-0 small_txt color_txt">
                {{ item.status_refund_at }}
              </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  v-if="item.status_withdraw == 2"
                  dark
                  depressed
                  color="cyan"
                  @click="confirmDana(item)"
                  class="mr-2"
                >
                  Konfirmasi
                </v-btn>
              </div>
            </template>
          </v-data-table>

          <div class="text-center pa-3">
            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="list.last_page"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import TarikDanaPsy from "./modal/tarikDanaPsy.vue";
export default {
  name: "manafementbalacne",
  components: { TarikDanaPsy },
  computed: {
    ...mapState({
      profile: (state) => state.psycholog.myProfile,
    }),
  },
  data() {
    return {
      dialogDana: false,
      list: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "AMOUNT",
          align: "start",
          value: "amount",
        },
        {
          text: "REQUEST DATE",
          align: "start",
          value: "status_withdraw_at",
        },
        {
          text: "STATUS",
          align: "start",
          value: "status_withdraw",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
        },
      ],
      page: 1,
      limit: 10,
      setStatus: "",
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      let data = {
        page: this.page,
        limit: this.limit,
        status: this.setStatus,
      };
      this.$store.dispatch("counseling/listWithdrawPsy", data).then((data) => {
        console.log(data);
        this.list = data;
      });
    },
    confirmDana(item) {
      let data = {
        id: item.id,
      };
      this.$store.dispatch("counseling/confirmWithdraw", data).then((data) => {
        console.log(data);
        this.fetchItems();
        Swal.fire({
          title: data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          position: "top",
          toast: true,
        });
      });
    },
  },
};
</script>

<style scoped>
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
.bagi3 {
  width: calc(100% / 3);
}
.active {
  background: #f3e5f5;
}
</style>
