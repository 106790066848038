<template>
  <v-row justify="start">
    <v-dialog v-model="dialogDana" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-3">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="pa-5" style="min-height: 200px">
          <p class="ma-0 small_txt color_txt">Jumlah yang akan di cairkan</p>
          <v-card outlined class="pa-2 mb-3">
            Rp.
            <input
              type="number"
              placeholder="ketik disini"
              v-model="amount"
              class="input_numb ml-2"
            />
          </v-card>
          <v-btn
            class="text-capitalize"
            dark
            color="orange"
            @click="tarekSis"
            depressed
            >Cairkan</v-btn
          >
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "penarikanDana",
  props: ["dialogDana", "dana"],

  data() {
    return {
      role: "",
      amount: 0,
      loading: false
    };
  },
  methods: {
    tarekSis() {
      let data = {
        amount: this.amount
      };
      if (this.amount >= 10000) {
        if (this.amount <= this.dana) {
          this.loading = true;
          this.$store.dispatch("counseling/reqWithdrawPsy", data).then(data => {
            this.loading = false;
            this.amount = 0;
            this.$emit("close");
            this.$emit("refetch");
            Swal.fire({
              title: data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          });
        } else {
          Swal.fire({
            title: "Jumlah yang di tarik lebih besar dari saldo anda!",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        }
      } else {
        Swal.fire({
          title: "minimal 10.000",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          position: "top",
          toast: true
        });
      }
    },
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>

<style scoped>
.input_numb:focus {
  outline: none;
}
</style>
